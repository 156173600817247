@import './roboto.css';

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: rgb(250, 250, 250);
  font-family: roboto, sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html.noScroll {
  overflow: hidden;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url('../../fonts/roboto/Roboto-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}
